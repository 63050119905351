import { Breadcrumb } from '@finn/auto-ui/components/Breadcrumbs/Breadcrumbs';
import { Locale, useCurrentLocale } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { SeoHeaderData } from '~/types/cosmicModules';

export { type Breadcrumb };

type UseBlogBreadcrumbs = {
  seoHeaderData: SeoHeaderData;
};

const IS_BLOG_ARTICLE_REGEX = /^(\/(blog|guide)\/)/;
const IS_BLOG_HOME = /^\/guide$/;

export const useBlogBreadcrumbs = ({ seoHeaderData }: UseBlogBreadcrumbs) => {
  const router = useRouter();
  const isBlogArticle = IS_BLOG_ARTICLE_REGEX.test(router.asPath);
  const isBlogHome = IS_BLOG_HOME.test(router.asPath);
  const i18n = useIntl();

  const autoAboTitle = i18n.formatMessage({
    id: 'general.breadcrumbs.auto_abo',
  });
  const blogTitle = i18n.formatMessage({ id: 'general.breadcrumbs.blog' });

  const { locale } = useCurrentLocale();
  const isBlog = isBlogArticle || isBlogHome;

  if (locale === Locale.ENGLISH_USA || !isBlog) return null;

  if (isBlogHome) {
    return [{ title: autoAboTitle, url: `/${locale}` }, { title: blogTitle }];
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: autoAboTitle, url: `/${locale}` },
    { title: blogTitle, url: `/${locale}/guide` },
  ];

  const articleTitle = seoHeaderData.metadata?.title;
  if (articleTitle) {
    breadcrumbs.push({ title: articleTitle });
  }

  return breadcrumbs;
};
